@font-face {
    font-family: 'Proxima Nova';
    src: url('https://d.nocimg.pl/css/fonts/v5/proximanova-regular.woff2') format('woff2');
    /*src: url('./../../assets/fonts/proximanova/proximanova-regular.woff2') format('woff2');*/
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('https://d.nocimg.pl/css/fonts/v5/proximanova-regularitalic.woff2') format('woff2');
    /*src: url('./../../assets/fonts/proximanova/proximanova-regularitalic.woff2') format('woff2');*/
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('https://d.nocimg.pl/css/fonts/v5/proximanova-bold.woff2') format('woff2');
    /*src: url('./../../assets/fonts/proximanova/proximanova-bold.woff2') format('woff2');*/
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('https://d.nocimg.pl/css/fonts/v5/proximanova-bolditalic.woff2') format('woff2');
    /*src: url('./../../assets/fonts/proximanova/proximanova-bolditalic.woff2') format('woff2');*/
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
