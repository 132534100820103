// react-datepicker variable override before styles import
$datepicker__background-color: var(--chakra-colors-white);
$datepicker__border-color: var(--datepicker-border-color);
// $datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #0050a0;
$datepicker__text-color: var(--chakra-colors-gray-900);
$datepicker__header-color: var(--chakra-colors-gray-900);
// $datepicker__navigation-disabled-color: lighten(
//   $datepicker__muted-color,
//   10%
// ) !default;
$datepicker__border-radius: 0;
// $datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.875rem;
$datepicker__font-family: inherit;
$datepicker__item-size: 2.25rem;
// $datepicker__margin: 0.4rem !default;
// $datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 4px;

// Import vendor styles AFTER overriding variables
@import '../../../../../../../node_modules/react-datepicker/src/stylesheets/datepicker.scss';

// Custom variables
$datepicker-day-border-radius: 0.25rem;

.react-datepicker__day[class] {
    vertical-align: middle;
    background-color: var(--datepicker-day-bg-color, transparent) !important;
    color: var(--datepicker-day-text-color, var(--chakra-colors-gray-900));
}

.react-datepicker__day--outside-month,
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    --datepicker-day-bg-color: transparent;
    --datepicker-day-text-color: var(--chakra-colors-gray-400);
}

.react-datepicker__day--in-range {
    --datepicker-day-bg-color: var(--day-in-range-bg);
    --datepicker-day-text-color: var(--chakra-colors-gray-900);
}

.react-datepicker__day--range-end,
.react-datepicker__day--range-start {
    --datepicker-day-bg-color: var(--day-range-selected);
    --datepicker-day-text-color: var(--chakra-colors-white);
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-start:hover {
    border-top-left-radius: $datepicker-day-border-radius !important;
    border-bottom-left-radius: $datepicker-day-border-radius !important;
}

.react-datepicker__day--range-end,
.react-datepicker__day--range-end:hover {
    border-top-right-radius: $datepicker-day-border-radius !important;
    border-bottom-right-radius: $datepicker-day-border-radius !important;
}

@media (hover: hover) {
    .react-datepicker__day:not(.react-datepicker__day--disabled):not(
            .react-datepicker__day--range-start
        ):not(.react-datepicker__day--range-end):hover {
        --datepicker-day-bg-color: var(--day-range-hover);
        --datepicker-day-text-color: var(--chakra-colors-white);
    }
}

.react-datepicker__day--in-range {
    &.react-datepicker__day--danger {
        --datepicker-day-bg-color: var(--chakra-colors-danger-100);
        --datepicker-day-text-color: var(--chakra-colors-danger-500);
    }
}

.react-datepicker__day--selection-start,
.react-datepicker__day--selection-start:hover {
    --datepicker-day-bg-color: var(--day-range-selected);
    --datepicker-day-text-color: var(--chakra-colors-white);

    border-top-left-radius: $datepicker-day-border-radius;
    border-bottom-left-radius: $datepicker-day-border-radius;
}

// Start selection and end selection should override `in-range`
.react-datepicker__day--selection-start,
.react-datepicker__day--range-end {
    &.react-datepicker__day--danger {
        --datepicker-day-bg-color: var(--chakra-colors-danger-500);
        --datepicker-day-text-color: var(--chakra-colors-white);
    }
}

// Other classes/states
//.react-datepicker__day--excluded {}
//.react-datepicker__day--weekend {}
//.react-datepicker__day--outside-month {}
//.react-datepicker__day--in-selecting-range {}
//.react-datepicker__day--selecting-range-start {}
//.react-datepicker__day--selecting-range-end {}

// https://github.com/Hacker0x01/react-datepicker/issues/2930
//.react-datepicker__day--keyboard-selected {}

.react-datepicker__navigation {
    top: 10px;
}

.react-datepicker__navigation-icon::before {
    top: 12px;
}

.react-datepicker__navigation-icon--previous::before {
    left: -4px;
}

.react-datepicker__navigation-icon--next::before {
    right: -4px;
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1em;
}

.react-datepicker__day-name,
.react-datepicker__current-month {
    text-transform: capitalize; // Visual fix for date-fns
}

.react-datepicker__day-name {
    color: var(--chakra-colors-grey-600);
}

.react-datepicker__header .react-datepicker__current-month {
    margin-bottom: 0.25rem;
    padding: 0.375rem 0;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
    width: 6rem;
    padding: 0.5rem 0;
}

.react-datepicker__month-container {
    padding: 0.5rem;
}

.react-datepicker__month-wrapper {
    display: flex;
}

.react-datepicker__header {
    border: 0;
}

.react-datepicker {
    border-radius: 0.25rem;
    box-shadow: var(--chakra-shadows-md);
}

.react-datepicker--drawer {
    display: block;
    border: 0;
    box-shadow: none;
    max-width: 480px;
    width: 100%;
    margin: 0 auto;

    .react-datepicker__month {
        margin: 0;
    }

    .react-datepicker__month-container {
        float: none;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
        display: flex;
        flex-wrap: wrap;
    }

    > .react-datepicker__day-names {
        font-size: 0.85rem;
        font-weight: normal;
        opacity: 0.75;
    }

    .react-datepicker__header .react-datepicker__day-names {
        display: none;
    }

    .react-datepicker__current-month {
        font-size: 1rem;
        margin-bottom: 0;
    }

    .react-datepicker__day--outside-month {
        --datepicker-day-bg-color: transparent;

        pointer-events: none;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 39px;
        width: calc(100% / 7);
    }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    margin: 0;
}

.react-datepicker-popper {
    z-index: var(--chakra-zIndices-modal);
}

.react-datepicker-popper--full {
    width: max-content;
}

.react-datepicker__day-inner {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

// Custom day modificators

.react-datepicker__day--no-departure,
.react-datepicker__day--min-cut-off,
.react-datepicker__day--no-arrival,
.react-datepicker__day--unavailable {
    --datepicker-day-text-color: var(--chakra-colors-gray-900);

    position: relative;
    overflow: hidden;
    isolation: isolate;

    > span {
        color: inherit;
    }

    &::before {
        content: '';
        position: absolute;
        left: -1px;
        right: -1px;
        bottom: -1px;
        top: -1px;
        background: var(--chakra-colors-gray-300);
        mix-blend-mode: multiply;
    }
}

.react-datepicker__day--min-cut-off.react-datepicker__day--range-end,
.react-datepicker__day--min-cut-off.react-datepicker__day--range-start,
.react-datepicker__day--no-arrival.react-datepicker__day--range-end,
.react-datepicker__day--no-arrival.react-datepicker__day--range-start {
    color: var(--chakra-colors-white);
}

.react-datepicker__day--no-departure {
    &::before {
        clip-path: polygon(0 0, 100% 0, 0 100%);
    }
}

.react-datepicker__day--min-cut-off,
.react-datepicker__day--no-arrival,
.react-datepicker__day--unavailable {
    &::before {
        clip-path: polygon(100% 0, 100% 100%, 0 100%);
    }
}

.react-datepicker--drawer {
    .react-datepicker__day--outside-month {
        opacity: 0;
    }
}

.react-datepicker-wrapper {
    display: block;
}
